/* ==========================================================================
   MAIN.SCSS
   ========================================================================== */
/**
 *
 * MAIN.CSS & MAIN.MIN.CSS are compiled using the imports from this file.
 *
 * To add new styles and components, import them in the appropriate sections below.
 *
 */
/* ==========================================================================
   HELPERS/_VARIABLES.SCSS
   ========================================================================== */
/* ==========================================================================
   HELPERS/_MIXINS.SCSS
   ========================================================================== */
/* Icon Bob */
@-webkit-keyframes hvr-icon-bob {
  0% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  50% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@keyframes hvr-icon-bob {
  0% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  50% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@-webkit-keyframes hvr-icon-bob-float {
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@keyframes hvr-icon-bob-float {
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
/* Hang */
@-webkit-keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  50% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
}
@keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  50% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
}
@-webkit-keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
}
@keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
}
/* ==========================================================================
   HELPERS/_RESET.SCSS
   ========================================================================== */
/* Border-Box http:/paulirish.com/2012/box-sizing-border-box-ftw/ */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body, div, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, ol, ul, li, form, legend, label, table, header, footer, nav, section, figure {
  margin: 0;
  padding: 0;
}

header, footer, nav, section, article, hgroup, figure {
  display: block;
}

/* ==========================================================================
   BASE/_LISTS.SCSS
   ========================================================================== */
ol {
  margin-left: 1.5em;
}

ul {
  list-style: none;
}

dl {
  overflow: hidden;
  margin: 0 0 1em;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

/* ==========================================================================
   BASE/_LINKS.SCSS
   ========================================================================== */
a {
  color: #b9d432;
  text-decoration: none;
  outline: 0;
  transition: color 250ms linear;
}
a:hover, a:focus {
  outline: 0;
  color: #fff;
}
a.chevron-link {
  color: #b9d432;
  position: relative;
}
a.chevron-link:after {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
  font-size: 0.8em;
  position: absolute;
  top: 4px;
  transition: right 250ms linear;
  right: -1.5em;
}
a.chevron-link:hover, a.chevron-link:focus {
  color: #fff;
}
a.chevron-link:hover:after, a.chevron-link:hover:after, a.chevron-link:focus:after, a.chevron-link:focus:after {
  right: -2em;
}

p.chevron-link a {
  color: #b9d432;
}
p.chevron-link a:after {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
  margin-left: 1em;
  transition: margin-left 250ms linear;
}
p.chevron-link a:hover:after, p.chevron-link a:focus:after {
  margin-left: 1.5em;
}

.link-icon {
  color: #b9d432;
  display: inline-block;
  position: relative;
}
.link-icon:before {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.5em;
}
.link-icon:hover:before, .link-icon:focus:before {
  animation-name: hvr-icon-bob-float, hvr-icon-bob;
  animation-duration: .3s, 1.5s;
  animation-delay: 0s, .3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;
}
.link-icon.download:before {
  content: "\e906";
}
.link-icon.location:before {
  content: "\e909";
}
.link-icon.map-view:before {
  content: "\e918";
}
.link-icon.list-view:before {
  content: "\e917";
}
.link-icon.play:before {
  content: "\e90e";
}

/* ==========================================================================
   BASE/_MEDIA.SCSS
   ========================================================================== */
img, object {
  max-width: 100%;
  height: auto;
}

iframe {
  margin-bottom: 1em;
}

figure {
  margin-bottom: 1em;
}
figure img {
  margin-bottom: 0.25em;
}

figcaption {
  font-style: italic;
}

#player1 {
  display: none;
  opacity: 0;
  position: relative;
  z-index: 11;
  transition: opacity 250ms linear;
}

#video-close {
  display: none;
  z-index: 12;
}

/**
 * Profound Grid
 *
 * Built in Sass (SCSS) this responsive/adaptive grid supports both fixed and fluid layouts,
 * relative and fixed gutters, as well as your choice of semantic markup or generic '.grid-x' classes.
 *
 * The biggest difference to other grid systems is the use of negative margins for column
 * placements, avoiding the subpixel rounding issues that usually break/uglify fluid layouts
 * in some browsers.
 *
 * Nested columns are supported too, without having to reset the grid on each level.
 *
 * Credits/Inspiration:
 * -------------------
 * Semantic Grid: http://www.semantic.gs
 * Susy: http://susy.oddbird.net
 * Negative Grid: http://chrisplaneta.com/freebies/negativegrid-fluid-css-grid-by-chris-planeta/
 *
 * @author Profound Creative Studio
 * @url http://www.profoundgrid.com
 * @url http://www.weareprofound.com
 */
/**
 * Clearfix
 */
/**
 * Legacy Clearfix
 */
/**
 * Establish the grid-containing element.
 */
/**
 * Align an element to the grid.
 */
/**
 * Apply to any column to make it the last one of the current row.
 */
/* ==========================================================================
   BASE/_FONTS.SCSS
   ========================================================================== */
/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * eathomegrown.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2016
 */
/* BROWN */
@font-face {
  font-family: "Brown";
  src: url("/_ui/fonts/brown/lineto-brown-regular.eot");
  src: url("/_ui/fonts/brown/lineto-brown-regular.eot?#iefix") format("embedded-opentype"), url("/_ui/fonts/brown/lineto-brown-regular.woff") format("woff"), url("/_ui/fonts/brown/lineto-brown-regular.ttf") format("truetype"), url("/_ui/fonts/brown/lineto-brown-regular.svg#lineto-brown-regular") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Brown";
  src: url("/_ui/fonts/brown/lineto-brown-italic.eot");
  src: url("/_ui/fonts/brown/lineto-brown-italic.eot?#iefix") format("embedded-opentype"), url("/_ui/fonts/brown/lineto-brown-italic.woff") format("woff"), url("/_ui/fonts/brown/lineto-brown-italic.ttf") format("truetype"), url("/_ui/fonts/brown/lineto-brown-italic.svg#lineto-brown-italic") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Brown";
  src: url("/_ui/fonts/brown/lineto-brown-bold.eot");
  src: url("/_ui/fonts/brown/lineto-brown-bold.eot?#iefix") format("embedded-opentype"), url("/_ui/fonts/brown/lineto-brown-bold.woff") format("woff"), url("/_ui/fonts/brown/lineto-brown-bold.ttf") format("truetype"), url("/_ui/fonts/brown/lineto-brown-bold.svg#lineto-brown-bold") format("svg");
  font-weight: 500;
  font-style: normal;
}
/* This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: https://www.linotype.com */
@font-face {
  font-family: "Orator W01 Medium";
  src: url("/_ui/fonts/orator/ee6930d9-1081-413a-9357-281913697b9a.eot?#iefix");
  src: url("/_ui/fonts/orator/ee6930d9-1081-413a-9357-281913697b9a.eot?#iefix") format("eot"), url("/_ui/fonts/orator/e4977404-64bf-4e3d-be03-5e314e0866a4.woff2") format("woff2"), url("/_ui/fonts/orator/ba28109f-1ade-45f5-bd09-a1ac3aec73f6.woff") format("woff"), url("/_ui/fonts/orator/9d480d68-3933-4c19-ac50-e7b49eba7165.ttf") format("truetype");
}
/* ICON FONT */
@font-face {
  font-family: 'icomoon';
  src: url("/_ui/fonts/icomoon.eot?iq0m5r");
  src: url("/_ui/fonts/icomoon.eot?iq0m5r#iefix") format("embedded-opentype"), url("/_ui/fonts/icomoon.ttf?iq0m5r") format("truetype"), url("/_ui/fonts/icomoon.woff?iq0m5r") format("woff"), url("/_ui/fonts/icomoon.svg?iq0m5r#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-catering-quote:before {
  content: "\e91a";
}

.icon-donation-request:before {
  content: "\e91b";
}

.icon-feedback:before {
  content: "\e91c";
}

.icon-our-team:before {
  content: "\e91d";
}

.icon-phone-assistance:before {
  content: "\e91e";
}

.icon-store-finder:before {
  content: "\e91f";
}

.icon-x:before {
  content: "\e919";
}

.icon-list-view:before {
  content: "\e917";
}

.icon-map-view:before {
  content: "\e918";
}

.icon-carrot-down:before {
  content: "\e900";
}

.icon-carrot-up:before {
  content: "\e901";
}

.icon-chevron-down:before {
  content: "\e902";
}

.icon-chevron-left:before {
  content: "\e903";
}

.icon-chevron-right:before {
  content: "\e904";
}

.icon-chevron-up:before {
  content: "\e905";
}

.icon-download:before {
  content: "\e906";
}

.icon-fb:before {
  content: "\e907";
}

.icon-instagram:before {
  content: "\e908";
}

.icon-location:before {
  content: "\e909";
}

.icon-logo:before {
  content: "\e90a";
}

.icon-mail:before {
  content: "\e90b";
}

.icon-minus:before {
  content: "\e90c";
}

.icon-order-now:before {
  content: "\e90d";
}

.icon-play:before {
  content: "\e90e";
}

.icon-plus:before {
  content: "\e90f";
}

.icon-slide-left .path1:before {
  content: "\e910";
  color: #231f20;
}

.icon-slide-left .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: white;
}

.icon-slide-left .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: white;
}

.icon-slide-right .path1:before {
  content: "\e913";
  color: #231f20;
}

.icon-slide-right .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: white;
}

.icon-slide-right .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: white;
}

.icon-twitter:before {
  content: "\e916";
}

/* ==========================================================================
   BASE/_LAYOUT.SCSS
   ========================================================================== */
html, body {
  height: 100%;
  position: relative;
}

#container {
  min-height: calc(100vh - 60px);
}

.container {
  width: 90%;
  margin: 0 5%;
  overflow: hidden;
  *zoom: 1;
}

.bg-offblack {
  background-color: #232323;
  color: #fff;
}

.bg-sagegray {
  background-color: #989E97;
  color: #fff;
}

@media screen and (min-width: 46.8em) {
  .container {
    margin: 0 auto;
    max-width: 1168px;
  }
}
@media screen and (max-width: 800px) {
  #content.no-hero {
    margin-top: 60px;
  }
}
@media screen and (min-width: 980px) {
  #container {
    min-height: calc(100vh - 100px);
  }
}
/* ==========================================================================
   BASE/_BUTTONS.SCSS
   ========================================================================== */
button {
  font-size: 1em;
}

.btn,
button {
  background-color: #b9d432;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Brown", helvetica, sans-serif;
  font-size: 1.4em;
  line-height: 1;
  padding: 1em 3em 1em 1em;
  position: relative;
  text-align: center;
  transition: color 250ms linear, background-color 250ms linear;
}
.btn:after,
button:after {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
  position: absolute;
  right: 1.5em;
  transition: right 250ms linear;
}
.btn:hover:after, .btn:focus:after,
button:hover:after,
button:focus:after {
  right: 1em;
}

.close-btn {
  color: #fff;
  font-size: 2em;
  transition: transform 150ms linear !important;
  position: absolute;
  top: 1em;
  right: 1em;
}
.close-btn.dark {
  color: #232323;
}
.close-btn:hover, .close-btn:focus {
  transform: rotate(90deg);
}

/* ==========================================================================
   BASE/_UTILITY.SCSS
   ========================================================================== */
.block {
  display: block;
}

.hide {
  display: none;
}

.offscreen {
  display: block;
  left: -999999px;
  position: absolute;
  top: -999999px;
}

.mobile-hidden {
  display: none;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before, .clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after {
  clear: both;
}

.hr {
  border-top: 1px solid #6a6d6a;
  display: block;
}
.hr hr {
  display: none;
}

.loading-screen {
  background-color: black;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
}
.loading-screen img {
  position: absolute;
  height: 200px;
  width: 200px;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transition-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (min-width: 980px) {
  .mobile-hidden {
    display: inline-block;
  }
}
/* ==========================================================================
   BASE/_TYPOGRAPHY.SCSS
   ========================================================================== */
body {
  color: #232323;
  font-family: "Brown", helvetica, sans-serif;
  font-size: 62.5% !important;
  font-weight: 300;
}

h1, .h1 {
  line-height: 1.2;
}

h2, .h2 {
  line-height: 1.2;
}

h3, .h3 {
  line-height: 1.2;
}

.headline {
  font-size: 2.1em;
  font-weight: 500;
  margin-bottom: 1em;
}
.headline.white {
  color: #fff;
}
.headline p {
  font-size: 1em;
}

.subhead {
  font-size: 1.8em;
  font-weight: 500;
  margin-bottom: 1em;
}
.subhead.white {
  color: #fff;
}

.title {
  font-family: "Orator W01 Medium", georgia, serif;
  margin-top: 0.5em;
  text-transform: uppercase;
}
.title:after {
  background-color: black;
  content: '';
  display: block;
  height: 1px;
  margin: 1em auto;
  width: 25px;
}

p {
  font-size: 1.6em;
  margin-bottom: 1em;
  line-height: 1.3em;
}
p.green {
  color: #b9d432;
}

blockquote {
  font-style: italic;
  border-left: 1px solid #6a6d6a;
  color: #6a6d6a;
  padding-left: 1em;
  margin-bottom: 1em;
}

abbr {
  border-bottom: 1px dotted #6a6d6a;
  cursor: help;
}

sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

sub {
  top: 0.4em;
}

@media screen and (min-width: 980px) {
  .headline {
    font-size: 3.2em;
    margin-bottom: 1em;
  }
  .headline p {
    font-size: 1em;
  }

  .subhead {
    font-size: 2.5em;
    margin-bottom: 1em;
  }
}
/* ==========================================================================
   BASE/_FORMS.SCSS
   ========================================================================== */
input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

form .alert {
  color: #b9d432;
  font-size: 1.4em;
  margin-top: -1px;
  font-family: "Brown", helvetica, sans-serif;
}
form .alert ul {
  padding-bottom: 0.5em;
}
form .alert ul li {
  border-bottom: none !important;
  padding-bottom: 0.5em;
}
form label {
  display: block;
  left: -999999px;
  position: absolute;
  top: -999999px;
}
form fieldset {
  border: 0;
  margin: 0 0 4em;
  padding: 0;
}
form fieldset p {
  margin-bottom: 2em;
}
form fieldset.container {
  border: 0;
  margin: 0 5% 4em;
  padding: 0;
}
form legend {
  font-size: 1.4em;
  margin-bottom: 4em;
}
form input[type="text"],
form input[type="tel"],
form input[type="email"],
form select,
form textarea {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  appearance: none;
  background-color: transparent;
  border-bottom: 1px solid #989E97;
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 1px solid #989E97;
  color: #989E97;
  display: block;
  font-family: "Brown", helvetica, sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  margin: -1px 0 0 0;
  padding: 1em 0;
  resize: none;
  width: 100%;
}
form input[type="text"] option,
form input[type="tel"] option,
form input[type="email"] option,
form select option,
form textarea option {
  background-color: #cbcecb;
}
form input[type="text"].hide,
form input[type="tel"].hide,
form input[type="email"].hide,
form select.hide,
form textarea.hide {
  display: none;
}
form input[type="text"]::placeholder,
form input[type="tel"]::placeholder,
form input[type="email"]::placeholder,
form select::placeholder,
form textarea::placeholder {
  color: #989E97;
  font-family: "Brown", helvetica, sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
}
form input[type="text"]:focus,
form input[type="tel"]:focus,
form input[type="email"]:focus,
form select:focus,
form textarea:focus {
  outline: none;
}
form input[type="text"].parsley-error,
form input[type="tel"].parsley-error,
form input[type="email"].parsley-error,
form select.parsley-error,
form textarea.parsley-error {
  color: #ff2400;
  border-bottom-color: #ff2400;
}
form input[type="text"].parsley-error::placeholder,
form input[type="tel"].parsley-error::placeholder,
form input[type="email"].parsley-error::placeholder,
form select.parsley-error::placeholder,
form textarea.parsley-error::placeholder {
  color: #ff2400;
}
form .parsley-required {
  display: none;
}
form .dropdown {
  position: relative;
}
form .dropdown:after {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e902";
  position: absolute;
  top: 2em;
  right: 1em;
}

/* ==========================================================================
   COMPONENTS/_MOBILE-NAV.SCSS
   ========================================================================== */
#header {
  background-color: transparent;
  position: fixed;
  top: 0;
  transition: background-color 250ms linear, top 250ms ease-in-out, opacity 150ms linear;
  width: 100%;
  z-index: 11;
}
#header.black, #header.active-black {
  background-color: black;
}
#header.nav-up {
  top: -60px;
}
#header #logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 980px) {
  #top-bar {
    overflow: hidden;
    *zoom: 1;
    height: 60px;
    padding: 1em 2em;
    text-align: center;
  }
  #top-bar a {
    color: #fff;
  }

  #primary-trigger {
    float: left;
  }

  #logo {
    font-size: 3.75em;
  }

  #order-now {
    font-size: 3.15em;
    float: right;
    padding-top: 0.15em;
    transition: color 250ms linear;
  }
  #order-now:hover, #order-now:focus {
    color: #b9d432;
  }

  #menu {
    overflow: hidden;
    *zoom: 1;
    background-color: black;
    height: calc(100vh - 60px);
    opacity: 0;
    padding-top: 1em;
    position: absolute;
    top: 60px;
    transition: opacity 250ms linear;
    opacity: 0;
    visibility: hidden;
    width: 100%;
  }
  #menu.active {
    opacity: 1;
  }
  #menu a {
    color: #fff;
    display: block;
    font-size: 1.8em;
    font-weight: 300;
    padding: 0.5em 0;
    transition: color 250ms linear;
  }
  #menu a:hover, #menu a:focus {
    color: #b9d432;
  }
  #menu a.back {
    font-size: 1em;
    font-weight: 500;
    letter-spacing: 2px;
    padding: 2em 0 0 0;
    text-transform: uppercase;
  }
  #menu a.back:before {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e902';
    font-size: 0.6em;
    margin-right: 1em;
  }
  #menu .left {
    float: left;
    padding-left: 2.2em;
    width: 50%;
  }
  #menu #region-list ul {
    padding-top: 2em;
  }
  #menu #region-list > a {
    font-size: 1.6em;
    padding: 2em 0;
    transition: background-color 250ms linear, color 250ms linear;
  }
  #menu #region-list > a .region-label {
    font-size: 0.625em;
    letter-spacing: 1px;
    margin-right: 0.5em;
    text-transform: uppercase;
  }
  #menu #region-list > a .arrow {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.625em;
    letter-spacing: 1px;
    margin-right: 0.5em;
    text-transform: uppercase;
  }
  #menu #region-list > a .arrow:after {
    content: "\e905";
    font-size: 0.5em;
    margin-left: 0.5em;
  }
  #menu #region-list > a:hover, #menu #region-list > a:focus {
    background-color: #b9d432;
    color: black;
  }

  #footer {
    float: right;
    width: 50%;
  }

  #bottom-bar {
    background-color: black;
    border-top: 1px solid #fff;
    clear: both;
    height: 100vh;
    position: absolute;
    text-align: center;
    top: calc(100vh - 143px);
    transition: top 250ms linear;
    width: 100%;
  }
  #bottom-bar.active {
    top: -85px;
  }

  .iPhoneSafari #bottom-bar {
    top: calc(100vh - 213px);
  }
  .iPhoneSafari #bottom-bar.active {
    top: -85px;
  }

  .iPadSafari #bottom-bar {
    top: calc(100vh - 163px);
  }
  .iPadSafari #bottom-bar.active {
    top: -85px;
  }
}
/* ==========================================================================
   COMPONENTS/_ALLERGEN-TABLE.SCSS
   ========================================================================== */
.table-container {
  width: 90%;
  margin: 0 5%;
  box-shadow: inset -10px 0 5px -5px rgba(0, 0, 0, 0.25);
  overflow-x: scroll;
}
.table-container .allergen-table {
  border-collapse: collapse;
  vertical-align: baseline;
  border-spacing: 0;
  font-size: 1.4em;
  table-layout: fixed;
  width: 1168px;
}
.table-container .allergen-table thead {
  background-color: #232323;
  color: #fff;
}
.table-container .allergen-table tr {
  border-bottom: 1px solid #cbcecb;
}
.table-container .allergen-table tr th {
  padding: 1em;
  width: 10%;
}
.table-container .allergen-table tr th.title {
  width: 20%;
}
.table-container .allergen-table tr td {
  padding: 1em;
}
.table-container .allergen-table tr td span {
  display: block;
  text-indent: -999999px;
}
.table-container .allergen-table tr td span.yes {
  background-color: #b9d432;
  border-radius: 9px;
  margin: 0 auto;
  height: 18px;
  width: 18px;
}
.table-container .allergen-table tr td:first-child {
  text-align: left;
  padding-left: 0;
}
.table-container .allergen-table tr td:nth-child(even) {
  background-color: rgba(152, 158, 151, 0.2);
}
.table-container .allergen-table tr td:last-child {
  vertical-align: top;
}

@media screen and (min-width: 73em) {
  .table-container {
    box-shadow: none;
    margin: 0 auto;
    max-width: 1168px;
    overflow: hidden;
  }
  .table-container .allergen-table {
    font-size: 1.6em;
  }
}
/* ==========================================================================
   COMPONENTS/_DESKTOP-NAV.SCSS
   ========================================================================== */
@media screen and (min-width: 980px) {
  #header {
    opacity: 0;
  }

  #top-bar {
    overflow: hidden;
    *zoom: 1;
    height: 60px;
    margin: 0 2em;
    max-width: 1168px;
    transition: height 250ms linear;
  }
  #top-bar a {
    color: #fff;
    display: inline-block;
    height: 60px;
    transition: color 250ms linear;
  }

  #primary {
    float: left;
  }
  #primary li {
    float: left;
    margin-right: 2em;
  }
  #primary li a {
    font-size: 1.4em;
    padding: 1.35em 0;
    border-top: 3px solid transparent;
    transition: color 250ms linear, padding 250ms linear;
  }
  #primary li a.active {
    border-color: #fff;
  }
  #primary li a:hover, #primary li a:focus {
    color: #b9d432;
  }
  #primary li:last-child {
    margin-right: 0;
  }

  #dt-right {
    float: right;
  }

  #logo {
    font-size: 4em;
    margin-top: 10px;
    transition: all 250ms linear !important;
  }

  #tagline {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 250ms linear, top 250ms linear;
    display: block;
    width: 178px;
    opacity: 0;
  }

  #secondary {
    float: left;
  }

  #region-list {
    float: left;
    font-size: 1.4em;
    margin-right: 2em;
    padding: 1.6em 0;
    letter-spacing: 1px;
    transition: padding 250ms linear;
  }
  #region-list a {
    height: auto;
  }
  #region-list a:hover,
  #region-list a:focus {
    color: #b9d432;
  }
  #region-list .region-label {
    font-size: 0.715em;
    text-transform: uppercase;
    margin-right: 1em;
  }
  #region-list .arrow {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    margin-right: 0.5em;
    text-transform: uppercase;
  }
  #region-list .arrow:after {
    content: "\e902";
    margin-left: 0.5em;
  }
  #region-list ul {
    background-color: black;
    display: block;
    margin-top: 1.5em;
    padding: 2em 2em 1em 2em;
    position: absolute;
  }
  #region-list ul li {
    margin-bottom: 1em;
  }
  #region-list ul li:last-child {
    display: none;
  }

  #locations {
    float: left;
    font-size: 1.4em;
    margin-right: 2em;
    padding: 1.35em 0;
    border-top: 3px solid transparent;
    transition: padding 250ms linear, color 250ms linear !important;
  }
  #locations.active {
    border-color: #fff;
  }
  #locations:hover, #locations:focus {
    color: #b9d432;
  }

  #order-now {
    float: left;
    font-size: 4em;
    padding: 0.25em 0;
    transition: padding 250ms linear, color 250ms linear !important;
  }
  #order-now:hover, #order-now:focus {
    color: #b9d432;
  }
}
@media screen and (min-width: 1100px) {
  #header.thick #top-bar {
    height: 130px;
  }
  #header.thick #top-bar #primary a,
  #header.thick #top-bar #locations {
    padding: 3.5em 0;
  }
  #header.thick #top-bar #order-now {
    padding: 1em 0;
  }
  #header.thick #top-bar #region-list {
    padding: 3.7em 0;
  }
  #header.thick #top-bar #logo {
    font-size: 7em;
    left: 50%;
    transform: translateX(-50%);
  }
  #header.thick #top-bar #tagline {
    top: 90px;
    opacity: 1;
  }
}
@media screen and (min-width: 1220px) {
  #top-bar {
    margin: 0 auto;
  }
}
/* ==========================================================================
   COMPONENTS/_CALCULATOR.SCSS
   ========================================================================== */
.calculator-body {
  padding: 4em 0;
  text-align: center;
}

#nutrition-calculator {
  width: 90%;
  margin: 0 5%;
}
#nutrition-calculator .mealtype-active .meal-type-select li.inactive {
  opacity: 0.5;
}
#nutrition-calculator .meal-type-select li {
  overflow: hidden;
  *zoom: 1;
  border-bottom: 1px solid #232323;
  padding: 2em 0;
  position: relative;
}
#nutrition-calculator .meal-type-select li a {
  color: #232323;
}
#nutrition-calculator .meal-type-select li a:hover, #nutrition-calculator .meal-type-select li a:focus {
  color: #b9d432;
}
#nutrition-calculator .meal-type-select li img {
  float: left;
  width: 25%;
  max-width: 100px;
}
#nutrition-calculator .meal-type-select li .copy {
  float: left;
  font-size: 1.8em;
  font-weight: 500;
  padding-left: 1em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 75%;
}
#nutrition-calculator .meal-type-select li .copy:after {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
  float: right;
}
#nutrition-calculator .select {
  position: relative;
  margin: 1em 0;
}
#nutrition-calculator .select select,
#nutrition-calculator .select .more-info {
  appearance: none;
  border-radius: 0;
  background-color: #232323;
  color: #b9d432;
  display: block;
  font-family: "Brown", helvetica, sans-serif;
  font-size: 1.6em;
  font-weight: 500;
  height: 60px;
  padding: 1.3em;
  width: 100%;
}
#nutrition-calculator .select select:focus,
#nutrition-calculator .select .more-info:focus {
  outline: none;
}
#nutrition-calculator .select select:disabled, #nutrition-calculator .select select:disabled + .icon-chevron-down,
#nutrition-calculator .select .more-info:disabled,
#nutrition-calculator .select .more-info:disabled + .icon-chevron-down {
  color: #989E97;
}
#nutrition-calculator .select .icon-chevron-down {
  color: #b9d432;
  position: absolute;
  top: 2.5em;
  right: 1.5em;
}
#nutrition-calculator .select ul {
  background-color: #232323;
  font-size: 1.6em;
  max-height: 0;
  overflow: hidden;
  padding: 0 1em;
  transition: max-height 250ms linear, padding 250ms linear;
}
#nutrition-calculator .select ul.active {
  max-height: 500px;
  padding: 1em;
}
#nutrition-calculator .dietary-filter ul li {
  padding: 0.5em 0;
}
#nutrition-calculator .dietary-filter ul li a:before {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90f";
  font-size: 0.8em;
  margin: 0 1em 0 0.5em;
}
#nutrition-calculator .dietary-filter ul li a.active:before {
  content: "\e90c";
  font-size: 3px;
  padding: 0 4em 0 1em;
  position: relative;
  top: -2px;
}
#nutrition-calculator .nutrition-table {
  border-collapse: collapse;
  vertical-align: baseline;
  border-spacing: 0;
  table-layout: fixed;
}
#nutrition-calculator .nutrition-table tbody.inactive {
  display: none;
}
#nutrition-calculator .nutrition-table tr {
  border-bottom: 1px solid #232323;
}
#nutrition-calculator .nutrition-table tr .value {
  display: none;
}
#nutrition-calculator .nutrition-table tr.active .value {
  display: block;
}
#nutrition-calculator .nutrition-table tr.already-included {
  display: none;
}
#nutrition-calculator .nutrition-table tr.filtered-out {
  opacity: 0.5;
}
#nutrition-calculator .nutrition-table tr .toggle-btn {
  color: #232323;
  display: inline-block;
  text-align: left;
  width: 48px;
}
#nutrition-calculator .nutrition-table tr .toggle-btn .icon-minus {
  font-size: 3px;
}
#nutrition-calculator .nutrition-table tr .toggle-btn .icon-plus {
  font-size: 14px;
}
#nutrition-calculator .nutrition-table tr .toggle-btn:hover, #nutrition-calculator .nutrition-table tr .toggle-btn:focus {
  color: #b9d432;
}
#nutrition-calculator .nutrition-table th, #nutrition-calculator .nutrition-table td {
  display: none;
  font-size: 1.4em;
  padding: 1em 0;
  text-align: center;
  overflow: hidden;
}
#nutrition-calculator .nutrition-table th.toggle, #nutrition-calculator .nutrition-table th.group-name, #nutrition-calculator .nutrition-table th.ing-title, #nutrition-calculator .nutrition-table td.toggle, #nutrition-calculator .nutrition-table td.group-name, #nutrition-calculator .nutrition-table td.ing-title {
  text-align: left;
}
#nutrition-calculator .nutrition-table th.group-name, #nutrition-calculator .nutrition-table td.group-name {
  background-color: #232323;
  color: #fff;
  padding-left: 1em;
  text-align: left;
}
#nutrition-calculator tfoot {
  background-color: #b9d432;
  height: 60px;
  width: 100%;
  bottom: 0;
  padding: 0 5%;
  position: fixed;
  left: 0;
}
#nutrition-calculator tfoot tr {
  width: 90%;
  margin: 0 5%;
}
#nutrition-calculator tfoot tr td {
  display: none;
}
#nutrition-calculator tfoot tr td.totals-title, #nutrition-calculator tfoot tr td.calories {
  display: table-cell;
}

@media screen and (min-width: 73em) {
  .calculator-body {
    margin-top: 60px;
    padding: 6em 0;
  }

  #nutrition-calculator {
    margin: 0 auto;
    max-width: 1168px;
    width: 100%;
  }
  #nutrition-calculator .filter-row {
    overflow: hidden;
    *zoom: 1;
    margin: 2em 0;
  }
  #nutrition-calculator .filter-row .select {
    float: left;
    margin-right: -100%;
    width: 30%;
  }
  #nutrition-calculator .filter-row .select:nth-child(2) {
    margin-left: 35%;
  }
  #nutrition-calculator .filter-row .select:nth-child(3) {
    margin-left: 70%;
  }
  #nutrition-calculator .table-body {
    position: relative;
  }
  #nutrition-calculator .table-body .meal-name {
    font-size: 3.2em;
    padding: 0;
    position: absolute;
    top: 100px;
    text-align: left;
    max-width: 448px;
  }
  #nutrition-calculator .meal-type-select {
    overflow: hidden;
    *zoom: 1;
  }
  #nutrition-calculator .meal-type-select li {
    float: left;
    margin-right: -100%;
    width: 12.5%;
    border-bottom: 0;
    padding: 1em;
    text-align: center;
  }
  #nutrition-calculator .meal-type-select li:nth-child(2) {
    margin-left: 17.5%;
  }
  #nutrition-calculator .meal-type-select li:nth-child(3) {
    margin-left: 35%;
  }
  #nutrition-calculator .meal-type-select li:nth-child(4) {
    margin-left: 52.5%;
  }
  #nutrition-calculator .meal-type-select li:nth-child(5) {
    margin-left: 70%;
  }
  #nutrition-calculator .meal-type-select li:nth-child(6) {
    margin-left: 87.5%;
  }
  #nutrition-calculator .meal-type-select li img {
    float: none;
    width: 100px;
  }
  #nutrition-calculator .meal-type-select li .copy {
    display: block;
    float: none;
    margin-top: 1em;
    padding-left: 0;
    position: relative;
    text-align: center;
    transform: none;
    width: 100%;
  }
  #nutrition-calculator .meal-type-select li .copy:after {
    content: '';
  }
  #nutrition-calculator .nutrition-table {
    border-top: 1px solid #232323;
  }
  #nutrition-calculator .nutrition-table th, #nutrition-calculator .nutrition-table td {
    display: table-cell;
    width: 48px;
  }
  #nutrition-calculator .nutrition-table th.toggle-btn, #nutrition-calculator .nutrition-table td.toggle-btn {
    width: 48px;
  }
  #nutrition-calculator .nutrition-table th.img, #nutrition-calculator .nutrition-table td.img {
    background-color: transparent !important;
    width: 48px;
  }
  #nutrition-calculator .nutrition-table th.img img, #nutrition-calculator .nutrition-table td.img img {
    width: 48px;
  }
  #nutrition-calculator .nutrition-table th.ing-title, #nutrition-calculator .nutrition-table td.ing-title {
    background-color: transparent;
    padding-left: 20px;
    width: 352px;
  }
  #nutrition-calculator .nutrition-table thead .thead {
    height: 200px;
    white-space: nowrap;
  }
  #nutrition-calculator .nutrition-table thead .thead > div {
    transform: translate(0, 56px) rotate(270deg);
    width: 48px;
  }
  #nutrition-calculator .nutrition-table tbody td:nth-child(even) {
    background-color: rgba(152, 158, 151, 0.2);
  }
  #nutrition-calculator .nutrition-table tfoot {
    padding: 0;
  }
  #nutrition-calculator .nutrition-table tfoot tr {
    border: 0;
    margin: 0 auto;
    max-width: 1168px;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  #nutrition-calculator .nutrition-table tfoot tr td {
    display: table-cell;
    height: 60px;
    padding: 0;
  }
  #nutrition-calculator .nutrition-table tfoot tr td:nth-child(even) {
    background-color: rgba(152, 158, 151, 0.2);
  }
  #nutrition-calculator .nutrition-table tfoot tr td:first-child {
    color: #fff;
    font-size: 3.2em;
    font-weight: bold;
    text-align: left;
    width: 448px;
  }
}
/* ==========================================================================
   COMPONENTS/_DIALOGS.SCSS
   ========================================================================== */
.status-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 250ms linear;
}

.dialog {
  background-color: rgba(0, 0, 0, 0.9);
  display: none;
  opacity: 0;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  transition: opacity 250ms linear;
  width: 100%;
  z-index: 20;
}
.dialog .box {
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #000;
  padding: 3em;
  position: fixed;
  height: auto;
  left: 50%;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 95%;
  max-width: 550px;
}
.dialog .box img {
  width: 25px;
}
.dialog .box .close-btn {
  color: black;
}
.dialog .box .close-btn:hover, .dialog .box .close-btn:focus {
  color: black;
}
.dialog .more-info {
  background-color: #232323;
  color: #b9d432;
  display: block;
  font-size: 1.8em;
  font-weight: 500;
  margin-top: 2em;
  margin-bottom: -2px;
  padding: 1.5em;
  text-align: left;
}
.dialog .more-info .icon-chevron-down {
  float: right;
  font-size: 0.6em;
  padding-top: 0.5em;
}
.dialog .content {
  background-color: #232323;
  font-size: 1.6em;
  text-align: left;
  padding-bottom: 1em;
}
.dialog .content a {
  display: block;
  color: #b9d432;
  padding: 0.5em 1.5em;
}
.dialog .content a:hover, .dialog .content a:focus {
  color: #fff;
}

#newsletter input[type="email"] {
  border-top: 0;
  margin: -1px 0 2em 0;
}
#newsletter button:hover, #newsletter button:focus {
  outline: none;
}

@media screen and (min-width: 980px) {
  #newsletter input[type="email"] {
    margin-top: 0;
  }
}
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  display: none;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}
.owl-carousel .owl-nav div {
  transition: all 250ms linear;
  display: block;
  font-size: 4.8em;
  height: 48px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 48px;
  transform: translateY(-50%);
}
.owl-carousel .owl-nav div.owl-prev {
  left: 5%;
}
.owl-carousel .owl-nav div.owl-next {
  right: 5%;
}
.owl-carousel .owl-nav div:hover, .owl-carousel .owl-nav div:focus {
  cursor: pointer;
  opacity: 0.7;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* ==========================================================================
   COMPONENTS/_TEXT-BLOCKS.SCSS
   ========================================================================== */
.large-centered {
  padding: 4em 0;
  text-align: center;
}
.large-centered .container .icon {
  margin-bottom: 2em;
  width: 68px;
}

@media screen and (min-width: 980px) {
  .large-centered {
    padding: 6em 0;
  }
  .large-centered .container {
    max-width: 800px;
  }
}
/* ==========================================================================
   COMPONENTS/_LOCATIONS.SCSS
   ========================================================================== */
.locations footer {
  display: none;
}

.locations-text {
  overflow: hidden;
  *zoom: 1;
  background-color: #fff;
  position: absolute;
  top: 50vh;
  transition: top 250ms ease-in-out, height 250ms ease-in-out;
  width: 100%;
}
.locations-text.full {
  height: 100vh;
  top: 0;
  width: 100%;
  z-index: 10;
}
.locations-text.full .owl-stage {
  transform: translate3d(0px, 0px, 0px) !important;
  width: 90% !important;
}
.locations-text.full .owl-nav {
  display: none;
}
.locations-text.full .owl-item {
  border-bottom: 1px solid #989E97;
  padding-top: 2em;
}

#directory .chevron-link:hover,
#directory .chevron-link:focus {
  color: #b9d432;
}
#directory .owl-carousel {
  list-style-type: none;
  margin-left: 0;
  padding-bottom: 48px;
}
#directory .owl-carousel .owl-stage-outer {
  padding: 2em 0;
}
#directory .owl-carousel .owl-nav {
  display: none;
  border-top: 1px solid #989E97;
  bottom: 0;
  height: 48px;
  font-size: 0.5em;
  position: absolute;
  width: 100%;
}
#directory .owl-carousel .owl-nav .owl-prev,
#directory .owl-carousel .owl-nav .owl-next {
  display: inline-block;
  padding-top: 10px;
  position: relative;
  width: 50%;
}
#directory .owl-carousel .owl-nav .owl-prev:hover, #directory .owl-carousel .owl-nav .owl-prev:focus,
#directory .owl-carousel .owl-nav .owl-next:hover,
#directory .owl-carousel .owl-nav .owl-next:focus {
  background-color: #b9d432;
  opacity: 1;
}
#directory .owl-carousel .owl-nav .owl-prev {
  border-right: 1px solid #989E97;
  left: 0;
}
#directory .owl-carousel .owl-nav .owl-next {
  right: 0;
}
#directory .owl-carousel .third {
  padding: 0 5%;
}
#directory .owl-carousel .note {
  color: #989E97;
  font-weight: 500;
}

.options-bar {
  overflow: hidden;
  *zoom: 1;
  border-bottom: 1px solid #989E97;
  padding: 1em 5%;
  text-align: center;
}
.options-bar a {
  color: #b9d432;
  display: inline-block;
  font-size: 1.4em;
  padding: 1em 0;
}
.options-bar a.map-view {
  margin: 0 2em;
}
.options-bar a.active {
  color: #989E97;
}
.options-bar a.location {
  color: #b9d432;
}

@media screen and (min-height: 700px) and (min-width: 980px) {
  .locations-text {
    height: 30vh;
    top: 70vh;
  }
}
@media screen and (min-height: 700px) and (max-width: 980px) {
  #store-list {
    height: calc(50vh - 111px);
  }
}
@media screen and (min-width: 980px) {
  .locations footer {
    display: block;
    margin-top: 100px;
  }

  .locations-text {
    background-color: #fff;
  }
  .locations-text.full {
    height: 100vh;
    top: 60px;
    overflow: scroll;
  }

  #directory {
    margin: 0 auto;
    max-width: 1168px;
  }
  #directory .owl-carousel {
    padding-bottom: 0;
  }
  #directory .owl-carousel .owl-nav {
    display: block;
    border-top: 0;
    bottom: auto;
    font-size: 0.5em;
    position: static;
  }
  #directory .owl-carousel .owl-nav div {
    top: 30%;
  }
  #directory .owl-carousel .owl-nav .owl-prev,
  #directory .owl-carousel .owl-nav .owl-next {
    border: 0;
    position: absolute;
    width: auto;
  }
  #directory .owl-carousel .owl-nav .owl-prev:hover, #directory .owl-carousel .owl-nav .owl-prev:focus,
  #directory .owl-carousel .owl-nav .owl-next:hover,
  #directory .owl-carousel .owl-nav .owl-next:focus {
    color: #b9d432;
    background-color: transparent;
    opacity: 1;
  }
  #directory .owl-carousel .owl-nav .owl-prev.disabled,
  #directory .owl-carousel .owl-nav .owl-next.disabled {
    color: #cbcecb;
    cursor: default;
  }
  #directory .owl-carousel .owl-nav .owl-prev {
    left: 0.5em;
  }
  #directory .owl-carousel .owl-nav .owl-next {
    right: 0.5em;
  }
  #directory .owl-item li {
    overflow: hidden;
    *zoom: 1;
    padding: 4em 0;
    max-width: 1000px;
    margin: 0 auto;
  }
  #directory .owl-item li .third {
    float: left;
    margin-right: -100%;
    width: 30%;
  }
  #directory .owl-item li .third:nth-child(2) {
    margin-left: 35%;
  }
  #directory .owl-item li .third:nth-child(3) {
    margin-left: 70%;
  }
}
/* ==========================================================================
   COMPONENTS/_CAREERS.SCSS
   ========================================================================== */
.careers .intro {
  width: 90%;
  margin: 0 5%;
  padding: 4em 0;
  text-align: center;
}
.careers .table {
  margin-bottom: 4em;
}
.careers .table a:hover,
.careers .table a:focus {
  color: #b9d432;
  cursor: pointer;
  text-decoration: underline;
}
.careers .table .btn {
  margin-bottom: 1em;
}
.careers .table .btn:hover, .careers .table .btn:focus {
  color: #fff;
  text-decoration: none;
}
.careers .table .chevron-link {
  font-size: 1.4em;
  margin-left: 1em;
}
.careers .table .chevron-link:hover, .careers .table .chevron-link:focus {
  text-decoration: none;
}
.careers table {
  border-collapse: collapse;
  font-size: 1.2em;
  width: 100%;
}
.careers table thead {
  background-color: #232323;
  color: #fff;
  text-align: left;
}
.careers table tbody tr:nth-child(even) {
  background-color: rgba(152, 158, 151, 0.2);
}
.careers table th, .careers table td {
  padding: 1em;
}
.careers .jobdescription,
.careers .requirements,
.careers .addinfo {
  font-size: 1.4em;
  padding: 0 1em;
}
.careers .jobtitle {
  padding: 1em;
}
.careers .addinfo {
  margin-bottom: 4em;
}

#benefits,
#grow-with-us {
  padding: 4em 0;
}
#benefits .third, #benefits .half,
#grow-with-us .third,
#grow-with-us .half {
  margin-bottom: 4em;
}

@media screen and (min-width: 46.8em) {
  .careers .intro {
    margin-top: 60px;
    padding: 6em 0;
  }

  #benefits,
  #grow-with-us {
    overflow: hidden;
    *zoom: 1;
  }
  #benefits .third,
  #grow-with-us .third {
    float: left;
    margin-right: -100%;
    width: 30%;
  }
  #benefits .two-thirds,
  #grow-with-us .two-thirds {
    float: left;
    margin-right: -100%;
    width: 65%;
    margin-left: 35%;
  }
}
@media screen and (min-width: 980px) {
  #benefits,
  #grow-with-us {
    padding: 6em 0 0;
  }
  #benefits .two-thirds .half,
  #grow-with-us .two-thirds .half {
    float: left;
    padding-bottom: 6em;
    width: 47.5%;
  }
  #benefits .two-thirds .half:nth-child(2n+1),
  #grow-with-us .two-thirds .half:nth-child(2n+1) {
    margin: 0 2.5% 0 0;
    clear: left;
  }
  #benefits .two-thirds .half:nth-child(2n+2),
  #grow-with-us .two-thirds .half:nth-child(2n+2) {
    margin: 0 0 0 2.5%;
  }

  .careers .table {
    margin-bottom: 6em;
  }
  .careers table {
    font-size: 1.6em;
  }
}
/* ==========================================================================
   COMPONENTS/_ACCORDION.SCSS
   ========================================================================== */
.accordion {
  border-top: 1px solid #989E97;
}
.accordion li {
  overflow: hidden;
  *zoom: 1;
  border-bottom: 1px solid #989E97;
}
.accordion li a {
  color: #232323;
}
.accordion li a:hover, .accordion li a:focus {
  color: #b9d432;
}
.accordion li .row {
  overflow: hidden;
  *zoom: 1;
  padding: 2em 0;
  position: relative;
}
.accordion li .row h3 {
  display: inline-block;
  font-size: 1.6em;
  width: 90%;
}
.accordion li .row .expand {
  display: inline-block;
  height: 25px;
  position: absolute;
  transform: rotate(0deg);
  transition: transform 250ms linear;
  width: 25px;
  right: 0;
  top: 1.75em;
}
.accordion li .row .expand:before {
  background-color: #b9d432;
  content: '';
  height: 1px;
  position: absolute;
  top: 12px;
  transition: background-color 250ms linear;
  width: 100%;
}
.accordion li .row .expand:after {
  background-color: #b9d432;
  content: '';
  height: 100%;
  position: absolute;
  right: 12px;
  transition: background-color 250ms linear;
  width: 1px;
}
.accordion li .row .expand.open {
  transform: rotate(45deg);
}
.accordion li .content {
  color: #6a6d6a;
  max-height: 0;
  overflow: hidden;
  transition: max-height 250ms linear, padding 250ms linear;
}

/* ==========================================================================
   COMPONENTS/_OUR-STORY.SCSS
   ========================================================================== */
#our-stores,
#sourcing-guidelines {
  padding: 4em 0;
}

#our-stores ul li {
  display: block;
  float: left;
  font-size: 1.6em;
  font-weight: 500;
  margin: 2em auto 0;
  text-align: center;
  width: 49%;
}
#our-stores ul li img {
  margin: 0 auto 1em;
  width: 100px;
}
#our-stores ul li span {
  display: block;
  margin: 0 auto;
  max-width: 175px;
}
#our-stores ul li:nth-child(2n + 1) {
  clear: left;
}

#sourcing-guidelines .guidelines {
  margin-top: 4em;
}

@media screen and (min-width: 39.8em) {
  #our-stores ul li {
    display: inline-block;
    width: 33%;
  }
  #our-stores ul li:nth-child(2n + 1) {
    clear: none;
  }
  #our-stores ul li:nth-child(3n + 1) {
    clear: left;
  }
}
@media screen and (min-width: 980px) {
  #our-stores,
  #sourcing-guidelines {
    padding: 6em 0;
  }
  #our-stores .half,
  #sourcing-guidelines .half {
    float: left;
    margin-right: -100%;
    width: 21.25%;
  }
  #our-stores .half:nth-child(2),
  #sourcing-guidelines .half:nth-child(2) {
    float: left;
    margin-right: -100%;
    width: 47.5%;
    margin-left: 52.5%;
  }

  #our-stores ul li {
    width: 20%;
  }
  #our-stores ul li:nth-child(3n + 1) {
    clear: none;
  }

  #sourcing-guidelines .guidelines {
    margin-top: 6em;
  }
}
/* ==========================================================================
   COMPONENTS/_PRESS.SCSS
   ========================================================================== */
.press .intro {
  width: 90%;
  margin: 0 5%;
  padding: 4em 0;
  text-align: center;
}
.press .intro .headline {
  margin: 0 auto;
  max-width: 445px;
}

.logo-soup {
  text-align: center;
}
.logo-soup a {
  display: block;
  opacity: 0.2;
  transition: opacity 250ms linear;
}
.logo-soup a:hover, .logo-soup a:focus {
  opacity: 0.8;
}

@media screen and (min-width: 29.75em) {
  .logo-soup {
    margin: 0 auto;
    max-width: 1168px;
  }
  .logo-soup a {
    float: left;
    width: 47.5%;
  }
  .logo-soup a:nth-child(2n+1) {
    margin: 0 2.5% 0 0;
    clear: left;
  }
  .logo-soup a:nth-child(2n+2) {
    margin: 0 0 0 2.5%;
  }
}
@media screen and (min-width: 46.8em) {
  .press .intro {
    margin-top: 60px;
    padding: 6em 0;
  }

  .logo-soup a {
    width: 30%;
  }
  .logo-soup a:nth-child(2n+1) {
    clear: none;
  }
  .logo-soup a:nth-child(3n+1) {
    margin: 0 2.5% 0 0;
    clear: left;
  }
  .logo-soup a:nth-child(3n+2) {
    margin: 0 2.5%;
  }
  .logo-soup a:nth-child(3n+3) {
    margin: 0 0 0 2.5%;
  }
}
/* ==========================================================================
   COMPONENTS/_CATERING.SCSS
   ========================================================================== */
#info-and-serving-options {
  padding: 4em 0;
}
#info-and-serving-options .half:nth-child(2) {
  margin-top: 6em;
  text-align: center;
}
#info-and-serving-options .half:nth-child(2) .row {
  margin: 0 auto 4em;
  max-width: 500px;
}
#info-and-serving-options .half:nth-child(2) .row:last-child {
  margin-bottom: 0;
}
#info-and-serving-options .half:nth-child(2) img {
  margin-bottom: 2em;
}

#place-an-order {
  padding: 4em 0;
  text-align: center;
}
#place-an-order img {
  width: 25px;
}
#place-an-order a {
  color: #fff;
}
#place-an-order a:hover, #place-an-order a:focus {
  color: #b9d432;
}
#place-an-order .title:after {
  background-color: #fff;
}
#place-an-order .third {
  margin: 4em 0;
}
#place-an-order .third:last-child {
  margin-bottom: 0;
}

#request-a-quote {
  padding: 4em 0;
}
#request-a-quote .two-thirds {
  margin-top: 4em;
}

#faq {
  padding: 4em 0;
  width: 90%;
  margin: 0 5%;
}
#faq .two-thirds {
  margin-top: 4em;
}

@media screen and (min-width: 46.8em) {
  #place-an-order {
    overflow: hidden;
    *zoom: 1;
  }
  #place-an-order .row,
  #place-an-order .container {
    margin: 0 auto;
    max-width: 800px;
  }
  #place-an-order .third {
    float: left;
    margin-right: -100%;
    width: 30%;
  }
  #place-an-order .third:nth-child(2) {
    margin-left: 35%;
  }
  #place-an-order .third:nth-child(3) {
    margin-left: 70%;
  }
}
@media screen and (min-width: 800px) {
  #faq,
  #request-a-quote {
    margin: 0 auto;
    padding: 6em 0;
  }
  #faq .third,
  #request-a-quote .third {
    float: left;
    margin-right: -100%;
    width: 30%;
  }
  #faq .two-thirds,
  #request-a-quote .two-thirds {
    float: left;
    margin-right: -100%;
    width: 65%;
    margin-left: 35%;
    margin-top: 1px;
  }
}
@media screen and (min-width: 980px) {
  #info-and-serving-options {
    padding: 6em 0;
  }
  #info-and-serving-options .half {
    float: left;
    margin-right: -100%;
    width: 38.75%;
  }
  #info-and-serving-options .half:nth-child(2) {
    float: left;
    margin-right: -100%;
    width: 47.5%;
    margin-left: 52.5%;
    margin-top: 0;
    text-align: left;
  }
  #info-and-serving-options .half:nth-child(2) .row {
    overflow: hidden;
    *zoom: 1;
  }
  #info-and-serving-options .half:nth-child(2) img {
    float: left;
    margin-right: -100%;
    width: 21.25%;
  }
  #info-and-serving-options .half:nth-child(2) .copy {
    float: left;
    margin-right: -100%;
    width: 73.75%;
    margin-left: 26.25%;
  }
}
/* ==========================================================================
   COMPONENTS/_CONTACT.SCSS
   ========================================================================== */
.get-in-touch {
  padding: 4em 0;
  text-align: center;
}
.get-in-touch .icon {
  margin-top: 2em;
  max-width: 26px;
}
.get-in-touch a {
  color: #b9d432;
}
.get-in-touch p {
  font-weight: 500;
  margin: 1em auto;
  max-width: 300px;
}
.get-in-touch p.wide {
  max-width: 700px;
}

#contact-form {
  padding: 4em 0;
}
#contact-form .headline {
  margin-bottom: 2em;
  text-align: center;
}
#contact-form form {
  margin: 0 auto;
  max-width: 800px;
}

.contact .accordion {
  margin-bottom: 4em;
}

@media screen and (min-width: 46.8em) {
  .get-in-touch {
    overflow: hidden;
    *zoom: 1;
    margin-top: 60px;
    padding: 6em 0;
  }
  .get-in-touch .half {
    float: left;
    margin-right: -100%;
    width: 47.5%;
  }
  .get-in-touch .half:nth-child(2) {
    margin-left: 52.5%;
  }

  #contact-form {
    padding: 6em 0;
  }
}
@media screen and (min-width: 73em) {
  .get-in-touch .half {
    margin-left: 8.75%;
  }
  .get-in-touch .half:nth-child(2) {
    margin-left: 43.75%;
  }
}
/* ==========================================================================
   COMPONENTS/_BLOG.SCSS
   ========================================================================== */
.grid {
  width: 90%;
  margin: 0 5%;
  margin-bottom: 4em;
  text-align: center;
}
.grid .grid-sizer,
.grid .grid-item {
  width: 100%;
}
.grid .grid-item {
  border-bottom: 1px solid #232323;
  padding: 2em 0 1em;
}
.grid .grid-item .title {
  margin-top: 2em;
}
.grid .grid-item h2 {
  margin: 1em 0 0.5em;
}
.grid .grid-item h2 a {
  color: #232323;
}
.grid .grid-item .date {
  font-family: "Orator W01 Medium", georgia, serif;
}
.grid .grid-item a:hover, .grid .grid-item a:focus {
  color: #b9d432;
}

.blog-single {
  width: 90%;
  margin: 0 5%;
  padding: 4em 0;
}
.blog-single .title, .blog-single h1 {
  text-align: center;
  margin: 0.5em auto 1em;
  max-width: 400px;
}
.blog-single img {
  margin: 1em 0 2em;
}

.next-article {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
.next-article .filter {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4em 5%;
  height: 100%;
  width: 100%;
}
.next-article .filter p {
  margin-bottom: 0;
}

@media screen and (min-width: 46.8em) {
  .grid {
    margin: 0 2.5%;
    width: 95%;
  }
  .grid .grid-sizer,
  .grid .grid-item {
    margin: 0 2.5%;
    width: 45%;
  }
  .grid .grid-item:nth-child(2), .grid .grid-item:nth-child(3) {
    padding-top: 0;
  }

  .blog-single {
    margin-top: 60px;
  }
  .blog-single .copy-block {
    margin: 4em auto;
    max-width: 575px;
  }

  .next-article .filter {
    padding: 6em 0;
  }
}
@media screen and (min-width: 980px) {
  .grid {
    margin: 0 auto 6em;
    max-width: 1168px;
  }
  .grid .grid-sizer,
  .grid .grid-item {
    margin: 0 2.5%;
    width: 28.333333%;
  }
  .grid .grid-item:nth-child(4) {
    padding-top: 0;
  }

  .blog-single {
    margin: 60px auto 0;
    max-width: 980px;
    padding: 6em 0;
  }
  .blog-single h1 {
    margin-bottom: 2em;
  }

  .next-article .filter {
    padding: 10em 0;
  }
}
/* ==========================================================================
   COMPONENTS/_MENU.SCSS
   ========================================================================== */
#seasonal {
  padding: 4em 0;
}
#seasonal .half:first-child p {
  max-width: 300px;
}
#seasonal .item {
  overflow: hidden;
  *zoom: 1;
  border-top: 1px solid #fff;
  margin: 4em 0 0;
  padding: 4em 0;
  position: relative;
}
#seasonal .item .copy {
  float: left;
  margin-right: -100%;
  width: 73.75%;
}
#seasonal .item .allergens {
  float: left;
  margin-right: -100%;
  width: 21.25%;
  margin-left: 78.75%;
  position: relative;
}
#seasonal .item .allergens .tooltip-trigger li a {
  background-color: transparent;
  border: 1px solid #fff;
  padding-top: 4px;
}
#seasonal .item .allergens .tooltip {
  background-color: #989E97;
  color: #fff;
  z-index: 10;
}
#seasonal .item .allergens .tooltip:after {
  border-left-color: #989E97;
}

.menu-group {
  border-bottom: 1px solid #232323;
}
.menu-group .intro {
  width: 90%;
  margin: 0 5%;
  text-align: center;
  margin-bottom: 4em;
}
.menu-group .intro img {
  width: 100px;
}
.menu-group .intro p {
  margin: 0 auto;
  max-width: 370px;
}

.menu .item {
  overflow: hidden;
  *zoom: 1;
  margin-bottom: 4em;
}
.menu .item .copy {
  float: left;
  margin-right: -100%;
  width: 73.75%;
}
.menu .item img {
  margin-bottom: 2em;
}
.menu .item .price {
  color: #989E97;
  font-size: 1.4em;
  font-weight: 500;
  margin-bottom: 0;
}
.menu .item .calories {
  color: #989E97;
  font-family: "Orator W01 Medium", georgia, serif;
  font-size: 1.2em;
  text-transform: uppercase;
}
.menu .allergens {
  float: left;
  margin-right: -100%;
  width: 21.25%;
  margin-left: 78.75%;
  position: relative;
}
.menu .allergens .tooltip-trigger li {
  float: right;
  padding: 0 0 2em 2em;
}
.menu .allergens .tooltip-trigger li:nth-child(even) {
  padding-left: 0;
}
.menu .allergens .tooltip-trigger li a {
  background-color: #232323;
  border-radius: 12px;
  color: #b9d432;
  font-size: 1.1em;
  font-weight: 500;
  display: block;
  height: 24px;
  padding-top: 5px;
  text-align: center;
  width: 24px;
}
.menu .allergens .tooltip-trigger:hover + .tooltip,
.menu .allergens .tooltip-trigger:focus + .tooltip {
  opacity: 1;
}
.menu .allergens .tooltip {
  background-color: #232323;
  color: #fff;
  display: inline-block;
  padding: 1.5em;
  opacity: 0;
  position: absolute;
  right: 105%;
  top: 0;
  transition: opacity 250ms linear;
  width: auto;
}
.menu .allergens .tooltip li {
  white-space: nowrap;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 0.5em;
}
.menu .allergens .tooltip li:last-child {
  margin-bottom: 0;
}
.menu .allergens .tooltip li span {
  color: #b9d432;
  margin-right: 0.5em;
}
.menu .allergens .tooltip:after {
  top: 8px;
  left: 100%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #232323;
  border-width: 5px;
}

#our-sources {
  padding: 4em 0;
}
#our-sources .group {
  margin-top: 4em;
}
#our-sources .group li {
  font-size: 1.6em;
  margin-bottom: 0.25em;
}
#our-sources .group li span {
  color: #6a6d6a;
}

@media screen and (min-width: 46.8em) {
  #seasonal {
    padding: 6em 0;
  }
  #seasonal .half {
    float: left;
    margin-right: -100%;
    width: 47.5%;
  }
  #seasonal .half:nth-child(2) {
    margin-left: 52.5%;
  }
  #seasonal .half .item {
    margin-top: 0;
  }

  .menu-group .item {
    float: left;
    width: 47.5%;
  }
  .menu-group .item:nth-child(2n+1) {
    margin: 0 2.5% 4em 0;
    clear: left;
  }
  .menu-group .item:nth-child(2n+2) {
    margin: 0 0 4em 2.5%;
  }
}
@media screen and (min-width: 980px) {
  .menu-group {
    padding: 6em 0;
  }
  .menu-group .intro {
    margin-bottom: 10em;
  }
  .menu-group .item {
    width: 30%;
  }
  .menu-group .item:nth-child(2n+1) {
    clear: none;
  }
  .menu-group .item:nth-child(3n+1) {
    margin: 0 2.5% 4em 0;
    clear: left;
  }
  .menu-group .item:nth-child(3n+2) {
    margin: 0 2.5% 4em;
  }
  .menu-group .item:nth-child(3n+3) {
    margin: 0 0 4em 2.5%;
  }

  #our-sources {
    overflow: hidden;
    *zoom: 1;
    padding: 6em 0;
  }
  #our-sources .third {
    float: left;
    margin-right: -100%;
    width: 30%;
  }
  #our-sources .two-thirds {
    float: left;
    margin-right: -100%;
    width: 65%;
    margin-left: 35%;
  }
  #our-sources .two-thirds .group {
    float: left;
    width: 47.5%;
  }
  #our-sources .two-thirds .group:nth-child(2n+1) {
    margin: 0 2.5% 4em 0;
    clear: left;
  }
  #our-sources .two-thirds .group:nth-child(2n+2) {
    margin: 0 0 4em 2.5%;
  }
}
/* ==========================================================================
   COMPONENTS/_CTAS.SCSS
   ========================================================================== */
.ctas a {
  color: #fff;
  display: inline-block;
  font-family: "Orator W01 Medium", georgia, serif;
  font-size: 1.6em;
  padding: 2em 3em;
  text-align: center;
  text-transform: uppercase;
  transition: color 250ms linear;
  width: 49%;
}
.ctas a span {
  font-size: 2em;
  display: block;
  margin: 0 auto 0.5em;
}
.ctas a:hover, .ctas a:focus {
  color: #b9d432;
}

@media screen and (min-width: 870px) {
  .ctas {
    padding: 3em 0;
  }
  .ctas a {
    font-size: 2.1em;
    padding: 3em 2em;
    width: 33%;
  }
  .ctas a span {
    font-size: 4em;
    display: block;
    margin: 0 auto 0.5em;
  }
}
/* ==========================================================================
   COMPONENTS/_ORDERS.SCSS
   ========================================================================== */
#order-online {
  background-color: black;
  color: #fff;
  height: 100vh;
  padding: 4em 5%;
  text-align: center;
}
#order-online h1 {
  margin-bottom: 4em;
}
#order-online .row {
  margin: 0 auto;
  max-width: 755px;
}
#order-online .half:first-child {
  margin-bottom: 6em;
}

@media screen and (min-width: 46.8em) {
  #order-online {
    padding: 15em 0;
  }
  #order-online .half {
    float: left;
    margin-right: -100%;
    width: 47.5%;
  }
  #order-online .half:nth-child(2) {
    margin-left: 52.5%;
  }
}
/* ==========================================================================
   COMPONENTS/_NUTRITION.SCSS
   ========================================================================== */
.icons-with-copy .block {
  margin-top: 4em;
}
.icons-with-copy .block img {
  margin-bottom: 1em;
}

@media screen and (min-width: 46.8em) {
  .icons-with-copy .block {
    overflow: hidden;
    *zoom: 1;
    float: left;
    text-align: left;
    width: 47.5%;
  }
  .icons-with-copy .block:nth-child(odd) {
    margin-right: 2.5%;
  }
  .icons-with-copy .block:nth-child(even) {
    margin-left: 2.5%;
  }
  .icons-with-copy .block:nth-child(2n+1) {
    clear: left;
  }
  .icons-with-copy .block img {
    float: left;
    margin-right: -100%;
    width: 21.25%;
  }
  .icons-with-copy .block .copy {
    float: left;
    margin-right: -100%;
    width: 73.75%;
    margin-left: 26.25%;
  }
}
/* ==========================================================================
   COMPONENTS/_SUBNAV.SCSS
   ========================================================================== */
.sticky-wrapper {
  background-color: #232323;
}

.subnav {
  background-color: #fff;
  border-bottom: 1px solid #232323;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  padding: 2em 5%;
  position: relative;
  transition: padding 250ms linear, top 250ms ease-in-out;
  width: 100%;
}
.subnav.dark {
  background-color: #989E97;
}
.subnav.dark ul li a {
  color: #6a6d6a;
}
.subnav.dark ul li a:hover, .subnav.dark ul li a:focus, .subnav.dark ul li a.active {
  color: #fff;
}
.subnav.shadow {
  box-shadow: inset -10px 0 5px -5px rgba(0, 0, 0, 0.25);
}
.subnav.stuck {
  padding: 1em 5%;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 10;
}
.subnav.stuck:after {
  height: 37px;
}
.subnav ul {
  margin: 0 auto;
}
.subnav ul li {
  float: left;
}
.subnav ul li a {
  color: #6a6d6a;
  font-size: 1.4em;
  padding-right: 2em;
  transition: color 250ms linear;
}
.subnav ul li a:hover, .subnav ul li a:focus, .subnav ul li a.active {
  color: #b9d432;
}

@media screen and (min-width: 980px) {
  .subnav {
    overflow-x: hidden;
  }
}
/* ==========================================================================
   COMPONENTS/_HERO.SCSS
   ========================================================================== */
.hero {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  height: 80vh;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100vw;
}
.hero.home {
  height: 100vh;
  max-height: 100vh;
}
.hero.home .content .headline {
  font-size: 2.8em;
}
.hero .bg-video {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hero .bg-video .filter {
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.hero .bg-video video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hero .content {
  position: absolute;
  padding: 0 5%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
}
.hero .content .headline {
  color: #fff;
  font-size: 2.1em;
  font-weight: 300;
}
.hero .scroll {
  bottom: 1em;
  color: #fff;
  display: block;
  font-size: 0.65em;
  letter-spacing: 1px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  transition: bottom 250ms linear;
  width: 100%;
  z-index: 2;
}
.hero .scroll a {
  color: #fff;
}
.hero .scroll span {
  display: block;
  margin: 0.5em 0 1em;
}
.hero .scroll:hover, .hero .scroll:focus {
  bottom: 0;
}

@media screen and (min-width: 46.8em) {
  .hero.home .content .headline {
    font-size: 4.8em;
  }
}
@media screen and (min-width: 980px) {
  .hero {
    height: 600px;
  }

  .hero {
    max-height: 600px;
  }
  .hero .content {
    left: 50%;
    max-width: 540px;
    padding: 0;
    transform: translateX(-50%) translateY(-50%);
  }
  .hero .content .headline {
    font-size: 3.2em;
  }
  .hero .content .links {
    text-align: center;
  }
  .hero .content .links p {
    display: inline-block;
  }
  .hero .content .links p:nth-child(2) {
    margin-left: 2em;
  }
}
/* ==========================================================================
   COMPONENTS/_HOMEPAGE.SCSS
   ========================================================================== */
.alternating-content picture img {
  margin: 5%;
  width: 90%;
}
.alternating-content .copy {
  margin: 2em 2em 4em;
  text-align: center;
}
.alternating-content .copy .icon {
  height: 25px;
  width: 25px;
}
.alternating-content .copy a:hover, .alternating-content .copy a:focus {
  color: #b9d432;
}

@media screen and (min-width: 777px) {
  .alternating-content {
    margin-bottom: 5%;
  }
  .alternating-content .row {
    overflow: hidden;
    *zoom: 1;
    margin: 0 auto;
    max-width: 1700px;
    position: relative;
  }
  .alternating-content .row picture img {
    float: left;
    margin-right: -100%;
    width: 65%;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: -5%;
    padding: 5% 0 0 5%;
  }
  .alternating-content .row .copy {
    float: left;
    margin-right: -100%;
    width: 30%;
    margin-left: 70%;
    margin-bottom: 0;
    padding-right: 5%;
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
  }
  .alternating-content .row:nth-child(even) picture img {
    float: left;
    margin-right: -100%;
    width: 65%;
    margin-left: 35%;
    padding: 5% 5% 0 0;
  }
  .alternating-content .row:nth-child(even) .copy {
    float: left;
    margin-right: -100%;
    width: 30%;
    margin-left: 0%;
    padding: 0 0 0 5%;
  }
}
@media screen and (min-width: 73em) {
  .alternating-content {
    margin-bottom: 2.5%;
  }
  .alternating-content .row picture img {
    padding: 2.5% 0 0 2.5%;
  }
  .alternating-content .row .copy {
    padding-right: 2.5%;
  }
  .alternating-content .row:nth-child(even) picture img {
    padding: 2.5% 2.5% 0 0;
  }
  .alternating-content .row:nth-child(even) .copy {
    padding: 2.5% 0 0 2.5%;
  }
}
/* ==========================================================================
   COMPONENTS/_FOOTER.SCSS
   ========================================================================== */
footer {
  background-color: black;
  height: 60px;
  text-align: center;
  padding: 1.6em 0;
}
footer .social {
  overflow: hidden;
  *zoom: 1;
  display: inline-block;
  margin: 0 auto;
}
footer .social li {
  float: left;
  margin-right: 1em;
}
footer .social li:last-child {
  margin-right: 0;
}
footer .social li a {
  color: #fff;
  font-size: 2.5em;
  line-height: 1.1em;
  transition: color 250ms linear;
}
footer .social li a:hover, footer .social li a:focus {
  color: #b9d432;
}

@media screen and (min-width: 980px) {
  footer {
    overflow: hidden;
    *zoom: 1;
  }
  footer #footer {
    float: left;
  }
  footer #footer li {
    float: left;
    margin-right: 2em;
  }
  footer #footer a {
    color: #fff;
    display: inline-block;
    font-size: 1.4em;
    padding: 0.5em 0;
  }
  footer #footer a:hover, footer #footer a:focus {
    color: #b9d432;
  }
  footer .social {
    float: right;
  }
}
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 12px 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger.hidden {
  display: none;
}
.hamburger:hover {
  opacity: 1;
}

.hamburger-box {
  width: 20px;
  height: 16px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 20px;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -7px;
}
.hamburger-inner::after {
  bottom: -7px;
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease, opacity 0.1s 0.14s ease;
}
.hamburger--squeeze.active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}
